.odm_header{
    background: #5c7cfa;
    display:table;
    table-layout: fixed;
    width:100%;
}

.odm_item{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table-cell ;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.odm_item:hover{
    background: #748ffc;
}

.odm_item:active,.odm_item.active{
    background:rgb(61, 82, 173);
    color: #fff;
    font-weight: 700;
}

.odm_contents {position: relative; overflow: hidden;margin-top: 0px; margin-bottom: 2px;}

