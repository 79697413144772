.service_header{
    background: #5c7cfa;
    display:table;
    table-layout: fixed;
    width:100%;
}

.service_item{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table-cell ;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.service_item:hover{
    background: #748ffc;
}

.service_item:active,.service_item.active{
    background:rgb(61, 82, 173);
    color: #fff;
    font-weight: 700;
}

.service_contents {position: relative; overflow: hidden;margin-top: 0px; margin-bottom: 2px;}
.service_contents .column {margin: 0 auto; width: 80%; height:inherit; }
/* .service_contents .column .col1 {float: left; width: 50%; height: 400px;  background:rgba(255, 255, 255, 1.0);  }
.service_contents .column .col2 {float: right; width: 50%;  height: 400px; background:rgba(255, 255, 255, 1.0); }
.service_contents .column .col3 {clear: both; width: 100%;  height: 660px;  background:rgba(1,1,255,0.1);  }
.service_contents .column .col4 {float: left; width: 33%; height: 300px; background:rgba(1,1,255,0.1); }
.service_contents .column .col5 {float: left; width: 33%; height: 300px; background:rgba(1,1,255,0.1); }
.service_contents .column .col6 {float: left; width: 34%; height: 300px; background:rgba(1, 1, 255, 0.1);  }
.service_contents .column .col7 {clear: both; width: 100%; height: 160px; background:#eee } */
