@charset "UTF-8";

/* 스타일지정 */
body     {background: #fff;}
#out-box {  margin: 0 auto;  background: #cdd; }
#root    { margin: 0 auto; color: #cccccc; text-align: center; text-transform: none; opacity: 1.0;}

#banner  {background: url(../img/main_bg.png) no-repeat; background-size:cover;}
#contents {clear:both;}
#footer  {height: 200px; background: #00BFFF; margin-top: 0px; margin-bottom:  0px;text-transform: none;}

._container { height: inherit;width: 1600px; margin: 0 auto; background: rgba(0,0,0,0.1);}

/*헤더와 베너의 전체영역을 클래스로 다시 한번 김씨줌 */
.wrap_menu{ z-index: 0; margin: 0 auto;height: 800px; }

/* 헤더 */
/* .navbar  {background-color: tomato; height: 80px; line-height: 80px; font-size: 20px;color: #fff;text-decoration:none ; display:flex; flex-direction: row; justify-content: space-between;} */


.navbar{
  position: relative;
  display: -webkit-flex;
  display: flex;
  border-bottom: #198591 solid  1px;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding:  0 0px;
  width: 100%;
}



/* 메뉴를 감싼 영역 : 높이 고정 위해  */
.navbar .navbar_menu_wrap{
  display: -webkit-flex;
  display: flex;
  position: relative; 
  color: #d1dd26;
  border: #dd7f26 0px solid ;
  height: 76px;
}


/* 로고 */
.navbar .navbar_logo {
  color: #d1dd26;
  border: #8b26dd 0px solid ;
  height:50px; 
  width:200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 5px; margin-left: 20px;
  box-sizing: border-box;
  background: url(../nexfield_logo0.png) no-repeat;
  background-size: 80% 80%;
  background-position:center;
  margin-bottom: 10px;
}

.navbar_mobile{
  display: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: #d1dd26;
  width: 50px;
  height: 50px; line-height: 50px;
  text-align: center;
  padding-top: 14px;
  padding-left: 30px;
 
}

.navbar_mobile >a { border: 1px solid rgb(192, 192, 192); border-radius: 10%; width:35px;  height: 35px; line-height: 35px; color: rgb(192, 192, 192); font-size: 22px; font-weight: 700;}

.navbar_mobile >a:hover {color: #d1dd26; border-color:#d1dd26; font-size: 22px;}
.navbar:hover  {background-color: rgb(0, 24, 36);}

#root.mobile .navbar_menu_wrap {display:none}
#root.mobile  .wrap_menu .banner_contents_wrap {display:-webkit-flex;display:flex}




/* 메뉴 영역  */
.navbar .navbar_menu  { display:-webkit-flex; display:flex;   z-Index:2;  margin: 0px auto;  border: cyan 0px solid ;}


.navbar .navbar_logo span {display: -webkit-flex;display: flex; width: inherit;height: inherit;font-size: 30px; font-weight: 1000;display: none;  background: rgba(0,0,0,0.5);}
.navbar .navbar_logo:hover {-webkit-filter: brightness(110%);filter: brightness(110%) }
.navbar .navbar_logo a:hover,
.navbar .navbar_logo a:active,
.navbar .navbar_logo a:focus {border: 10px;}

/* 메뉴 */
/* navbar_menu 영역은 float:left로 지정하면 수평으로 배열은 되나 높이기 깨지고  높이룰 지정해주면 그 만큼의 공간을 차지해서 화면이 아래로 밀린다
   => 전체메뉴영역에 대한 높이를 주는대신 특정 메뉴에 해당하는 ul에 높이를 준다 
        ul 영역은 float 된 영역상에 있어서 외부에는 높이에 대한 영향을 미치지 않는듯
   z-Index 는 해당영역이 전면으로 표시되게 하기 위함
*/

.navbar .navbar_menu > li {width: 120px; height: 50px;line-height: 50px;border-radius: 4px;}
.navbar .navbar_menu li > a { font-size:20px; font-weight: 700;text-decoration: none;color: #fff; } 
.navbar .navbar_menu li ul li a{font-weight: 100;}  

/* menu contents */
.navbar .navbar_menu .menu_contents { display:none; overflow: hidden;background: #046; text-align: left; border-top: solid 1px #000;border-radius: 4px;padding: 2px 12px;width: 160px; }
.navbar .navbar_menu .menu_contents > li {width: inherit; height: inherit;}
.navbar .navbar_menu .menu_contents > li > a {display: inline-block; width:100%; color: #fff; }
.navbar .navbar_menu .menu_contents > li  :hover{ font-weight: 700;}
.navbar_menu_wrap .menu_titles.active  {background: #046} /* 메인메뉴의 액티브 컬러 */
.navbar_menu_wrap .menu_titles.active .menu_contents {display: -webkit-flex;display: flex;-webkit-flex-direction: column;flex-direction: column; width: 100%; margin: 0;padding: 0 10px;width:150px;} /* 서브메뉴의 폭을 넓게 설정 */



 /*모바일메뉴 */ 

 
/* 언어선택 */
/* 언어선택 영역  */
.navbar .navbar_language{  display: -webkit-flex;  display: flex; -webkit-flex-direction: row; flex-direction: row; border: #8b26dd 0px solid ;  width: 150px; text-align: center;  height:30px; line-height: 30px;}
.navbar .navbar_language {padding-right: 50px; }
.navbar .navbar_language li {display: inline-block;}

.navbar .navbar_language li a { font-size: 15px; font-weight: 700;text-align: right; padding: 10px 0px 10px 13px; text-decoration:none; color: #fff;transition: color 0.3s ease; }
.navbar .navbar_language li a:hover {border-bottom: 1px dotted black;font-weight: bolder;color: #0cc;text-decoration: none;}

.navbar .navbar_language li a.active  {font-weight: bolder;color: #0cc;text-decoration: none; }

.navbar .navbar_login{  display: -webkit-flex;  display: flex; -webkit-flex-direction: row; flex-direction: row; border: #8b26dd 0px solid ;  width: 180px; text-align: center;  height:30px; line-height: 30px;}
.navbar .navbar_login {padding-right: 50px; }
.navbar .navbar_login li {display: inline-block;}

.navbar .navbar_login li a { font-size: 15px; font-weight: 700;text-align: right; padding: 10px 0px 10px 13px; text-decoration:none; color: rgb(204, 200, 139);transition: color 0.3s ease; }
.navbar .navbar_login li a:hover {font-size: 16px ;border-bottom: 1px dotted black;font-weight: bolder;color:rgb(243, 228, 13);text-decoration: none;}

.navbar .navbar_login li a.active  {font-weight: bolder;color: #0cc;text-decoration: none; }




/* 구분라인 */
.wrap_menu .navbar_seperator {display: block;position: absolute;top: 80px; padding: 0;margin: -1px; border: solid 1px ;color:#006666;}

/* 컨텐츠 */
.wrap_menu .banner_contents_wrap {display:-webkit-flex;display:flex;clear: both; position: relative;  font-size: 40px;color: #fff;  text-align: left; height: 720px;} /* 배너헤더 높이 빼고... */
.wrap_menu .banner_contents_wrap .banner_intro {display: inline-block; position: absolute; left: 0px; top: 10px; z-index: 1; width:100%;opacity: 0.3;}
.wrap_menu .banner_contents_wrap .banner_intro strong {display: inline-block; width:70%; margin-left:24px; text-align: center; background: #040;opacity: 1;}
.wrap_menu .banner_contents_wrap .banner_intro em {display: inline-block; width:27%; margin: 0 auto; text-align: center; background: #004;opacity: 1;}


/* 베너 타이틀 */
.wrap_menu .banner_contents_wrap .banner_contents  {position: absolute; right: 10%;top: 200px; width: 640px;height:400px; background-color: white;opacity: 0.5;}

.wrap_menu .banner_contents_wrap .banner_contents .banner_title {overflow: hidden;width: 100%;height: 25%; z-index: 1;display: block; 
      font-size: 40px; font-weight: 700;  text-align: left;  text-transform: none;}

.wrap_menu .banner_contents_wrap .banner_contents .banner_title ul { height: inherit;}
/* .wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li {height: inherit;} */
.wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li a {overflow: hidden; white-space: nowrap; text-overflow: clip; display: none; padding-left: 30px;position: absolute; color: #000; line-height: 100px;text-decoration: none; text-align: left; opacity: 1.0;}
.wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li a:hover {color: #00f; font-size: 41px;}
.wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li:first-child a {display: block; }

/* 슬릭베너 디스크립션 */
.wrap_menu .banner_contents_wrap .banner_contents .banner_desc {overflow: hidden;width: 100%;height: 75%; z-index: 1;display: block; 
  font-size: 40px; color: #fff; text-align: left;  text-transform: none;background: #08f;}
.wrap_menu .banner_contents_wrap .banner_contents .banner_desc ul {margin-top: 40px;}
.wrap_menu .banner_contents_wrap .banner_contents .banner_desc ul li {padding-left: 30px;font-size: 30px;font-weight: 500;}


/* 슬릭베너 */
.wrap_menu .banner_contents_wrap .banner_slick{ text-align: left; height: 98%;width: 98%;overflow: hidden;margin : 0 auto;padding: 0}
.wrap_menu .banner_contents_wrap .banner_slick .slick-prev {position: absolute; left: 50px; top: 50%; border: 0;outline: 0;font-size: 0; -webkit-transform: scaleY(3); transform: scaleY(3);z-index: 999;background: transparent;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-next {position: absolute;right: 50px; top: 50%; border: 0;outline: 0;font-size: 0; -webkit-transform: scaleY(3); transform: scaleY(3);z-index: 999;background: transparent;}

/* 좌우 페이지전환 버튼 */
.wrap_menu .banner_contents_wrap .slick-prev::before { content: "\f053";font-family: FontAwesome; color: #88f; font-size: 36px; padding-right: 0.5em;}
.wrap_menu .banner_contents_wrap .slick-next::before { content: "\f054";font-family: FontAwesome; color: #88f; font-size: 36px; padding-right: 0.5em;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-prev:hover::before {color: #f88;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-next:hover::before {color: #f88;}

.wrap_menu .banner_contents_wrap .banner_slick img {margin : 0 auto;border: 0px solid #dcdcdc; width: 100%; height: 100%; }
.wrap_menu .banner_contents_wrap .banner_slick img:hover {border-color: #98bcdc;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-slide {margin: 10px;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-dots {position: absolute; bottom: 30px; display: block; width: 100%; text-align: center;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-dots li {display: inline-block; width: 15px; height: 15px; margin: 10px; }
.wrap_menu .banner_contents_wrap .banner_slick .slick-dots li button {font-size: 0; line-height: 0; display: block; width: 15px; height: 15px; cursor: pointer; background: transparent; border-radius: 50%; border: solid 1px #fff;}

.wrap_menu .banner_contents_wrap .banner_slick .slick-dots li.slick-active button {background: #88f;border: 0; outline: 0;}
.wrap_menu .banner_contents_wrap .banner_slick .slick-dots li button:hover {background-color: #f88;}

.contents {position: relative; overflow: hidden;margin-top: 2px;}
.contents .column {float: left; }
.contents .col1 {float: left; width: 30%;  height: 300px; }
.contents .col2 {float: left; width: 30%;  height: 300px; background:rgba(1,255,1,0.1); }
.contents .col3 {float: right; width: 40%;  height: 600px;  background:rgba(1,1,255,0.1);  }
.contents .col4 {float: left; width: 30%; height: 300px; background:rgba(1,255,255,0.1); }
.contents .col5 {float: left; width: 30%; height: 300px; background:rgba(255,255,1,0.1); }
.contents .col6 {width: 100%;  height: 1200px;   background:rgba(1,1,255,0.1);  display: none}

/* col2 : movie */
.movi {position: relative; border: 1px solid #ccc; height: 300px; overflow: hidden;}
.movi h4 {text-align: left;font-size: 14px; color: #0093bd; border-bottom: 1px solid #ccc; padding: 10px 0px 8px 11px; font-weight: bold;}
.movi .movi_btn {position: absolute; right: 5px; top: 7px;}
.movi .movi_btn button {float: left; margin: 1px 2px; display: block; width: 23px; height: 23px; background: url(../img/icon.png) no-repeat;}

.movi .movi_btn .movi_icon1 {background-position: -150px -120px}
.movi .movi_btn .movi_icon2 {background-position: -150px -143px}
.movi .movi_btn .movi_icon3 {background-position: -150px -166px}
.movi .movi_btn .movi_icon4 {background-position: -150px -189px}

.movi .movi_btn .movi_icon1:hover {background-position: -173px -120px}
.movi .movi_btn .movi_icon2:hover {background-position: -173px -143px}
.movi .movi_btn .movi_icon3:hover {background-position: -173px -166px}
.movi .movi_btn .movi_icon4:hover {background-position: -173px -189px}
.movi video { object-fit: fill;}

.movi .movi_img {text-align: center; }
.movi #col2_movi {width:60%; height:60%; }

/* col3 탭 메뉴 */

/*
  두번째 탭에서.. ul>li>ul 태크의 위치가 변한다
  디버깅해보니... tab선택시 active된  ul>li>ul 태크의 style이 display:none => display: block로 변한다
  =>  원인발견 : position: absolute 시 오류 : left:10 => left:10px 로 수정
*/
.tab_menu {position: relative;  border-bottom: 1px solid rgba(0, 0, 0, 0.171);margin-top: 2px; padding: 8px; height: 100%;}
.tab_menu > ul {margin-left: 5px;line-height:30px; overflow: hidden; border: 1px solid rgba(0, 0, 0, 0.171); }
.tab_menu ul li {float: left; border: 1px solid rgba(0, 0, 0, 0.171); margin-right: -1px; margin-bottom: -1px; }

.tab_menu ul li > a {padding: 5px 10px; text-decoration: none; font-weight: 700; }


.tab_menu ul li ul {display: none; text-align: left; position: absolute; left: 10px; top: 48px;  }
.tab_menu ul li ul li {width: 90%; border: 0; }
.tab_menu ul li ul li a {width: 90%;  padding: 0px 0px 3px 0px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;  font-weight: 500;}
.tab_menu ul li.active >a {color: rgb(21, 114, 121);}
.tab_menu ul li.active ul li a {color: #333;}
.tab_menu ul li ul li span {float: right; text-align: right;} 

/* 슬릭 갤러리 */
.gallery {position: relative; border: 1px solid #ccc; height: 300px; overflow: hidden;}
.gallery h4 {text-align: left;font-size: 14px; color: #0093bd; border-bottom: 1px solid #ccc; padding: 10px 0px 8px 11px; font-weight: bold;}
.gallery .gallery_btn {position: absolute; right: 5px; top: 7px;}
.gallery .gallery_btn button {float: left; margin: 1px 2px; display: block; width: 23px; height: 23px; background: url(../img/icon.png) no-repeat; }
.gallery .gallery_btn button:focus { outline: none}
.gallery .gallery_btn .gb_icon1 {background-position: -150px -120px}
.gallery .gallery_btn .gb_icon2 {background-position: -150px -143px}
.gallery .gallery_btn .gb_icon3 {background-position: -150px -166px}
.gallery .gallery_btn .gb_icon4 {background-position: -150px -189px}

.gallery .gallery_btn .gb_icon1:hover {background-position: -173px -120px}
.gallery .gallery_btn .gb_icon2:hover {background-position: -173px -143px}
.gallery .gallery_btn .gb_icon3:hover {background-position: -173px -166px}
.gallery .gallery_btn .gb_icon4:hover {background-position: -173px -189px}

.gallery_img {width:480px; margin: 5px auto; }
.gallery .gallery_text {
  padding: 5px 10px;
  background-color: rgb(24, 24, 22); 
  opacity: 0.7;
  text-align: center;
  position: absolute;
  color: #fff;
  font: italic bold 28px Georgia, serif;
  font-weight: 500;
  width: 100%;
  bottom: 11%; 
}

/* col4 */
.contents .col4 .dev_service {position: relative; border: 1px solid #ccc; margin-top: 0px; margin-right: 0px; padding: 0px; height: 100%; }
.contents .col4 .dev_service h4 {text-align: left;font-size: 14px; color: #0093bd; border-bottom: 0px solid #ccc; padding: 10px 0px 8px 11px; font-weight: bold;}
.contents .col4 .dev_service .movi_img {margin: 5px auto; height:320px;}

/* col5 */
.col5 .inquiry {overflow: hidden; position: relative;text-align: center; height: inherit;margin-top: 2px; height: 50%;}
.col5 .inquiry h4 {text-align: left;font-size: 14px; color: #0093bd; border-bottom: 0px solid #ccc; padding: 10px 0px 8px 11px; font-weight: bold;}
.col5 .inquiry .inquiry_info {height: 100%; }
.col5 .inquiry .inquiry_info::before { content: "\f025";font-family: FontAwesome; color: #0093bd; margin: 0 auto;font-size: 80px; padding-right: 1.5em;}
.col5 .inquiry .inquiry_info span {position: absolute; left: 150px; top:40px; color: #0093bd; font-weight: 1000;font-size: 20px;}
.col5 .inquiry .inquiry_info strong {position: absolute; left: 300px; top:40px;color: #0093bd; font-weight: 1000;font-size: 20px;}
.col5 .inquiry .inquiry_info em {position: absolute; left: 150px; top:75px;color: #0093bd; font-weight: 1000;}
.col5 .inquiry .inquiry_info a {position: absolute; left: 150px; top:105px;color: #0093bd; font-weight: 1000; display: block; font-style: normal; text-transform: none;text-decoration: underline;}
.col5 .inquiry .inquiry_info a:hover {font-size: 14px;}
.col5 .inquiry .inquiry_info {float: left;padding-left: 50px;}

/* 로그인 */
#login-wrap {position: relative;  border: 1px solid #ccc; margin-top: 0px; margin-right: 0px; padding: 0px; height: 50%; background: #f2f4f5;}
.login_navbar {padding-left: 15px;overflow: hidden; font-size: 12px; height: 30px; padding-top: 15px;}
.login_navbar .lh_check {float: left; padding-right: 15px;}
.login_navbar .lh_check .input_check { vertical-align: -2px;}
.login_navbar .lh_ip {float: left; }

.footer {  overflow: hidden; text-align: center; height: inherit;margin-top: 2px;}
.footer .footer_info {position: relative; float:left; width:20%; height: inherit;background: #009fcf; }
.footer .footer_info::before { content: "\f025";font-family: FontAwesome; color: #fff; margin: 0 auto;font-size: 120px; padding-right: 1.5em;}
.footer .footer_info span {position: absolute; left: 150px; top:50px; color: #fff; font-weight: 1000;}
.footer .footer_info strong {position: absolute; left: 150px; top:75px;color: #fff; font-weight: 1000;font-size: 20px;}
.footer .footer_info em {position: absolute; left: 150px; top:115px;color: #fff; font-weight: 1000;}
.footer .footer_info a {position: absolute; left: 70px; top:145px;color: #fff; font-weight: 1000; display: block; font-style: normal; text-decoration: underline;}
.footer .footer_info a:hover {font-size: 14px;}
.footer .footer_nav {float: left;padding-left: 50px;}

.footer address {color:#fff; text-align: left;padding-left: 150px;}
.footer address em {color:#fff;font-weight: 700;  display: inline-block;font-size: 14px; }
.footer address span {padding-left: 20px;color:#fff;display: inline-block;font-size: 14px; }
.footer address a {color: #fff; font-weight: 500; display: block; font-style: normal; text-decoration: underline;}

.footer ul {margin: 10px 10px 15px 50px; padding: 0 50px; line-height: 40px; background: #00bfff;display: inline-block;}
.footer li {position: relative; display: inline; padding: 0 7px 0 10px;font-size: 16px;}
.footer li:before {content: ''; width: 1px; height: 12px; background: #00f; position: absolute; left: 0; top: 2px;}
.footer li:first-child:before {width: 0; height: 0;}
.footer .w3c {display: none;margin-top: 20px;padding: 0 7px 10 10px;}


/* mediaquery */
/* 화면 너비 0~1600px */
@media (max-width: 1600px){
  .container {width: 100%;}
  .row {padding: 0 0px;}
._container {width: auto; }

.footer .footer_info { width: 320px;}

}

/* 화면 너비 0~1200px */
@media (max-width: 1200px){

    .navbar .navbar_logo { background-size: 70% 70%;}
    .navbar .navbar_language { padding-right: 5px;}
    
    /* .banner_contents_wrap {margin: 0 auto;} */
    .banner_contents_wrap  { margin: 0 auto;}

    .banner_contents_wrap .banner_contents .banner_title  {font-size: 22px;padding-left: 25px;}
    .banner_contents_wrap .banner_slick{padding-left: 2px;}
    .banner_contents_wrap .banner_slick img {width:  70%; height: 70%;}
    .banner_contents_wrap .banner_slick .slick-dots {width: 70%;}

    .footer address {color:#fff; text-align: left;padding-left: 60px;}
    .footer ul {margin: 10px 10px 15px 15px; padding: 0 30px; line-height: 40px; background: #00bfff;display: inline-block;}

}


/* 화면 너비 0~1060px */
@media (max-width:1060px){
  .container {width: 100%;}
  .row {padding: 0 0px;}
  .navbar .navbar_menu_wrap {display:-webkit-flex;display:flex};
   /* .navbar .navbar_logo {width: 150px; height: 30px;margin-bottom: 20px;} */
  .navbar .navbar_menu { margin: 2px 0px ; font-size: 18px; } 
  /* .navbar .navbar_language { padding-right: 5px; display: block; ;}
  .navbar .navbar_language > li {font-size: 10px}
  .navbar .navbar_menu li ul li { width: 120px; padding-left: 10px;}  */
  .navbar .navbar_language { display: none;}
  .navbar .navbar_login { display: none;}
  /* .banner_contents_wrap {margin: 0 auto;} */
  .banner_contents_wrap .banner_contents .banner_desc {display: block; }
  /* .banner_contents_wrap .banner_contents .banner_title ul li a {font-size: 22px;padding-left: 25px;} */
  .banner_contents_wrap .banner_slick{padding-left: 2px;}
  .banner_contents_wrap .banner_slick img {width:  70%; height: 70%;}
  .banner_contents_wrap .banner_slick .slick-dots {width: 70%;}

  .footer {display: -webkit-flex;display: flex; -webkit-flex-direction: column; flex-direction: column; width: 100%;}
  .footer .footer_info {display: none;}
  .wrap_menu .banner_contents_wrap{display:-webkit-flex;display:flex;};

  .navbar .navbar_menu {display:-webkit-flex;display:flex};

}


/* 화면 너비 0~800px*/
@media (max-width: 800px){
  .container {width: 100%;}
  .row {padding: 0 0px;}
 
  .navbar  {display:-webkit-flex;display:flex;-webkit-flex-direction: column;flex-direction: column; background-color: rgba(0, 24, 36,0.7);}
  .navbar_menu_wrap .menu_titles.active .menu_contents { margin:0; padding: 0; width:100%;} /* 서브메뉴의 폭을 넓게 설정 */


  .navbar_menu_wrap .menu_titles.active .menu_contents li {  margin:0; padding: 0; } 


  .navbar_mobile > a {font-weight: 500;}
  .navbar_mobile > a.active {font-weight: 700;}
   
  
  .navbar .navbar_language { display: none;}
  .navbar .navbar_logo { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; height:60px; line-height:60px; width:240px;}


  .navbar_mobile { position: absolute; top:10px; left: 0px; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; font-size: 20px;}
  .navbar .navbar_menu > li { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; height: auto; width: 150px;}
 
  .navbar .navbar_menu  .menu_titles.active {background-color: rgba(0, 24, 36,0.7);}
  .navbar .navbar_menu  .menu_titles:hover >a {background-color:#046;padding: 0 10px;}
  
   
  .navbar .navbar_menu  .menu_titles .menu_contents > li {padding: 0 10px; }
   
  /* 메뉴의 높이가 모두 동일하다 : 760~800 해상도에서만 보이므로 큰 문제가 없다 => 개선할것  */
  /* .navbar .navbar_menu  {height:250px; background-color: none;} */
  .navbar .navbar_menu  .menu_titles .menu_contents >a{width:180px;margin: 0 10px;}
  .wrap_menu{ z-index: 0; margin: 0 auto;height: 600px; }

  #banner  {background: url(../img/biz_iot.jpg) no-repeat; background-size:cover;}

  .navbar .navbar_menu_wrap {display:none;height:250px;   }
  /* .navbar .navbar_menu  .menu_titles:hover .navbar_menu_wrap {height:250px;  } */

  .navbar .navbar_language {display: none;}


 .banner_contents_wrap .banner_slick{padding-left: 2px;}
  .banner_contents_wrap .banner_slick img {width:  70%; height: 70%;}
  .banner_contents_wrap .banner_slick .slick-dots {width: 70%;}

  /* 컨텐츠 */
  .wrap_menu .banner_contents_wrap { margin-top: 30px; display: -webkit-flex; display: flex; position: relative; height: 100%;;} /* 배너헤더 높이 빼고... */


  .wrap_menu .banner_contents_wrap .banner_intro { display:-webkit-flex; display:flex ; -webkit-flex-direction: column; flex-direction: column; position: absolute; left:0; top:0; display: flex; font-size: 25px;}
  .wrap_menu .banner_contents_wrap .banner_intro strong {margin:0; padding: 0;display:block; width: 100%;}
  .wrap_menu .banner_contents_wrap .banner_intro em  {display:block; width: 100%;}
  /* .banner_contents_wrap .banner_contents .banner_desc {display:none ;position: absolute; left: 0;top: 150px;width: 90%;height: 80px; line-height: 80px; z-index: 1;display: block; margin: 0 5% auto;
    font-size: 22px; font-weight: 700;color: #fff;  text-align: left;  text-transform: none;background: #fff;opacity: 0.5;} */



  .wrap_menu .banner_contents_wrap .banner_contents {width:420px;height:280px;}
  .wrap_menu .banner_contents_wrap .banner_contents .banner_title {height: 80px;}
  .wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li a {font-size: 26px;padding-left: 25px;}
  /* .banner_contents_wrap .banner_contents .banner_title ul li a:hover{font-size: 26px;padding-left: 25px;} */

  .wrap_menu .banner_contents_wrap .banner_contents .banner_title ul li a:hover { font-size: 28px;}

  .wrap_menu .banner_contents_wrap .banner_contents .banner_desc {height: 75%;}
  .wrap_menu .banner_contents_wrap .banner_contents .banner_desc ul li {font-size: 22px;}

  .contents .col1 {width: 100%; }
  .contents .col2 {width: 100%; }
  .contents .col4 {width: 100%; }
  .contents .col5 {width: 100%; }
  .contents .col3 {display: none;} 
  .contents .col6 {display: -webkit-flex;display: flex;width: 100%;} 

  }

/* 화면 너비 0~760px*/

@media (max-width:760px){

  .container {width: 100%;}
  .row {padding: 0 0px;}
  /* .banner_contents_wrap  {display:none} */
  .navbar  {display:-webkit-flex;display:flex;-webkit-flex-direction: column;flex-direction: column; background-color: rgba(0, 24, 36,0.7)}

  .navbar:hover  {background-color: rgba(0, 24, 36,0.7);}
  .navbar .navbar_logo {  display: -webkit-flex;  display: flex;  -webkit-flex-direction: column;  flex-direction: column; height:60px; line-height:60px; width:240px; }
  .navbar .navbar_menu_wrap { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; width:100%; height: 100%;  line-height: 50px;  background : none;   margin-bottom: 10px; }

  .navbar .navbar_menu{   border-top: #198591 solid  1px; padding-top:10px; display:-webkit-flex; display:flex;  -webkit-flex-direction: column;  flex-direction: column; z-Index:1; width:100%; text-align: center;}
  .navbar .navbar_menu > li {  display:block; text-align: center; width:auto; height: auto; }
  .navbar .navbar_menu > li:hover { background-color: rgba(0, 24, 36,0.9);  }
  .navbar .navbar_menu > li ul { margin-bottom: 5px;  background-color: tomato; }
  .navbar .navbar_menu > li ul li a {  padding-left:50px; text-align: left; margin: 0 auto; }
  .navbar .navbar_menu > li ul li a:hover {background-color: rgba(75, 144, 207, 0.1);  }
  .navbar .navbar_menu .menu_contents { width: 100%; background-color: rgba(255, 255, 255, 0.1); }
 
  .navbar .navbar_menu  .menu_titles:hover >a {background-color:rgba(0, 24, 36,0.9);}

  .navbar_mobile{ position: absolute;  top:10px; left: 0px; display: -webkit-flex; display: flex;  -webkit-flex-direction: column;  flex-direction: column; font-size: 20px;}
  .navbar .navbar_language {display: none;}

  .wrap_menu .banner_contents_wrap .banner_contents  { position: absolute;top:150px;left:0px; width: 100%;}
  .wrap_menu .banner_contents_wrap .banner_contents  .banner_title {padding-left: 5px;}

  /* ul 및 li 영역이 조금 이상하다 : 하단에 다음 화면이 겹친다.. 일단 maring을 줘서 보이지 않게 했다  */
  .wrap_menu .banner_contents_wrap  .banner_contents  .banner_desc ul {margin-top: 10px; margin-bottom: 30px;}

  /* 슬릭베너 디스크립션 */


  /* 슬릭제거 */
  .wrap_menu .banner_contents_wrap .banner_slick  {display: none;}

  .footer {margin: 0 auto; padding: 0;border: 2px solid  #00afef }
  .footer ul { margin:5px auto; padding: 0px 0px; line-height: 30px; background: #00bfff;display: inline-block;margin-left: -20px;;margin-right: 20px;}

  .footer address {color:#fff; text-align: left;padding-left: 30px; padding-bottom: 50px;}
}

/* end */
