.prod_header{
    background: #5c7cfa;
    display:table;
    table-layout: fixed;
    width:100%;
}


.prod_item{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table-cell ;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.prod_item:hover{
    background: #5c7cfd;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
}

.prod_item:active,.prod_item.active{
    background:rgb(50, 73, 173);
    color: #fff;
    font-weight: 700;
}
/* 설정화면의 서브헤더 */
.prod_subheader{
    background:rgb(77, 99, 196);
    display:table;
    table-layout: fixed;
    width:100%;
}

.prod_subitem{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table-cell ;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.prod_subitem:hover{
    /* background: #748ffc; */
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.prod_subitem:active,.prod_subitem.active{
    background:rgb(50, 73, 173);
    color: #fff;
    font-weight: 700;
}




/* 설정화면의 서브헤더 */
.prod_subheader_2{
    background:rgb(61, 82, 173);
    display:table;
    table-layout: fixed;
    width:100%;
}

.prod_subitem_2{
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table-cell ;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.prod_subitem_2:hover{
    /* background: #748ffc; */
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.prod_subitem_2:active,.prod_subitem_2.active{
    background:rgb(50, 73, 173);
    color: #fff;
    font-weight: 700;
}

.prod_contents {position: relative; overflow: hidden;margin-top: 0px; margin-bottom: 2px;}
.prod_contents .column {margin: 0 auto; width: 80%; height:inherit; }
.prod_contents .column .col_tab{position: relative; display: flex; flex-direction: row;}
.prod_contents .column .col_tab .col1 { width: 40%; height: 400px;  background:rgba(255, 255, 255, 1.0);  }
.prod_contents .column .col_tab .col2 {width: 40%;  height: 400px; background:rgba(255, 255, 255, 1.0); }
.prod_contents .column .col3 {clear: both; width: 100%;  background:rgba(1,1,255,0.1);  }

/* 모바일화면 */
#root.mobile .prod_contents .column {margin: 0 auto; width: 100%; height:inherit; }

#root.mobile .prod_contents .column .col3 table {width: 90%; }

#root.mobile .prod_contents .column .col_tab .col2 {display: none;} 
