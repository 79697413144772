
.ck-content-wrap {
    color: black;
   
    text-transform: none;
      /* max-height: 500px; */
     /* min-height: 200px; */
     display: block;
}

/* placeholder 숨김 */
.ck-editor__main figcaption.ck-editor__editable.ck-editor__nested-editable.ck-placeholder{
    display:none
}



/* .ck-editor__editable:not(.ck-editor__nested-editable) {
    min-height: 400px;
} */

/* #root.mobile  .ck-content textarea {width: 340px; background-color: blueviolet;} */

#root.mobile .BoardContent-paper-2  {margin-left :0;margin-right :0; } 


.table{
    display:table;
    width:100%;
    table-layout:fixed;
}
.table_cell{
    display:table-cell;
    width:100px;
    border:solid black 1px;
}


.pageinfo  span {
    box-sizing: border-box;
    width: 30px;
    height: 20px;
    line-height: 20px;
    /* line-width: 20px; */
    margin: 0 auto;
    font-size: 10px;
    font-weight: 700;
    background-color: rgb(192, 192, 192);
    border:1px solid rgba(0, 0, 0, 0.37);
    text-align:center;
    display:inline-block; 
    text-decoration: none;
    margin: 3px ;
    border-radius: 10%;
}

.pageinfo  .jb {
    border-radius: 50%;
    width: 20px;
    font-size: 10px;
    background-color:  rgb(192, 192, 192);
 
}

.pageinfo  .active {
  
    background-color: rgb(192, 152, 196);
 
}

