/* .header{
    background: #5c7cfa;
    display:table;
    table-layout: fixed; 
    width:100%;
} */
/* .header{    
    height: 'auto !important';
    background: #ddeedd;
} */



.itemEven{
    height: 'auto !important';
    background: #f1eaea;
}
.itemOdd{
    height: 'auto !important';
    background: #eeeeee;
}


.itemText{
    text-transform: none;
}
.ck-editor__editable {
    min-height: 1000px !important;
}

#cke_19, #cke_18, #cke_22, #cke_46, #cke_45 {
    display:none;
}


.aaa {
    margin: 0 auto;
}